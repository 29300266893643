import React from 'react'

const AboutPage = () =>{
    return(
        <div>
            About me :)
        </div>
    )
}

export default AboutPage